

const url = () => {
    const env = process.env.ENVIRONMENT;
    let url: string = '';
    if (env === 'dev') {
        url = 'http://dev.leadingcloud.xyz:33180/ldportal/api'
    } else {
        url = 'https://b.lidebiji.com/ldportal/api'
    }
    return url;
}

const token = () => {
    const env = process.env.ENVIRONMENT;
    let tokenStr:string = '';
    if(env === 'dev'){
        tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55SWQiOjEwMDEwMjksInNhbHQiOiI1M2Y0NjBmMjYzMzg0YTMxYTllYWMxYTMwM2IyZThjZSIsImNsaWVudElkIjowLCJ0eXBlIjoxMiwidXNlcklkIjoyMDAxMDQ0LCJvcmdJZCI6Mn0.Boze-kAl0FafvaOIDsgcwnRkOnuQ5WROWd5jmGaogds'
    }else{
        tokenStr = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55SWQiOjAsInNhbHQiOiIzMWIxOGM0NjBjZWU0NmQwYWNkZjUxMzcwYTgxZTc4OCIsImNsaWVudElkIjowLCJ0eXBlIjoxMiwidXNlcklkIjoyMDAyNzUyLCJvcmdJZCI6Mn0.Wtbxt3cA6JZv8Un8aXrsy7xgJj7GrVrFZLpmflQswg4'
    }
    return tokenStr
}

const checkStatus = res => {
    if (200 >= res.status < 300) {
        return res;
    }
    console.log(`网络请求失败,${res.status}`)
    const error = new Error(res.statusText);
    error.response = response;
    throw error;
};

/**
 *  捕获成功登录过期状态码等
 * @param res
 * @returns {*}
 */
const judgeOkState = async res => {
    const cloneRes = await res.clone().json();
    //TODO:可以在这里管控全局请求
    if (cloneRes.code !== 200) {
        // alert(`${cloneRes.msg}${cloneRes.code}`);
        console.log(`message：${cloneRes.msg} code：${cloneRes.code}`)
    }
    return res;
};

/**
 * 捕获失败
 * @param error
 */
const handleError = error => {
    if (error instanceof TypeError) {
        // alert(`网络请求失败啦！${error}`);
        console.log(`网络请求失败啦！${error}`)
    }
    return {   //防止页面崩溃，因为每个接口都有判断res.code以及data
        code: -1,
        data: false,
    };
};

class http {
    /**
     *静态的fetch请求通用方法
     * @param url
     * @param options
     * @returns {Promise<unknown>}
     */
    static async staticFetch(url = '', options = {}) {
        const defaultOptions = {
            /*允许携带cookies 没有用到cookie，开发环境暂不设置 ，线上使用 */
            // credentials: 'include',
            /*允许跨域**/
            mode: 'cors',
            headers: {
                crossDomain: "true",
                Accept: "*/*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, X-Auth-Token",
                "Content-Type": "application/x-www-form-urlencoded",
                // 生产Token
                token: token(),
                lang: '2',
                region: '2',
            },
        };
        if (options.method === 'POST' || 'PUT') {
            defaultOptions.headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        const newOptions = { ...defaultOptions, ...options };
        console.log('newOptions', newOptions);
        return fetch(url, newOptions)
            .then(checkStatus)
            .then(judgeOkState)
            .then(res => res.json())
            .catch(handleError);
    }

    /**
     *post请求方式
     * @returns {Promise<unknown>}
     */
    post(params = {}, option = {}) {
        const options = Object.assign({ method: 'POST' }, option);
        //一般我们常用场景用的是json，所以需要在headers加Content-Type类型
        options.body = JSON.stringify(params);
        //可以是上传键值对形式，也可以是文件，使用append创造键值对数据
        if (options.type === 'FormData' && options.body !== 'undefined') {
            let params = new FormData();
            for (let key of Object.keys(options.body)) {
                params.append(key, options.body[key]);
            }
            options.body = params;
        }
        return http.staticFetch(url(), options); //类的静态方法只能通过类本身调用
    }

    /**
     * put方法
     * @param url
     * @returns {Promise<unknown>}
     */
    put(url, params = {}, option = {}) {
        const options = Object.assign({ method: 'PUT' }, option);
        options.body = JSON.stringify(params);
        return http.staticFetch(url, options); //类的静态方法只能通过类本身调用
    }

    /**
     * get请求方式
     * @param url
     * @param option
     */
    get(url, option = {}) {
        const options = Object.assign({ method: 'GET' }, option);
        return http.staticFetch(url, options);
    }
}

const requestFun = new http(); //new生成实例
export const { post, get, put } = requestFun;
export default requestFun;