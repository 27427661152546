
import React from "react"
import {BuryingPointFc} from "./buryingPoint"
import phone from '../assets/images/phone.png'

const FooterFc = () => {
    return (
        <>
            <section className="bg-secondary text-light" style={{padding: '5rem 0', textAlign: 'center'}}>
                <div className="container">
                    <div className="row g-4">
                        <div className="col-sm-4">
                            <h2 className="mb-4">
                                {/* Cooperation */}
                                合作
                            </h2>
                            <p><a
                                onClick={() => BuryingPointFc('VO', 'VO00300100101101', {action: '点击首页合作的bd@leadingcloud.xyz”'})}
                                className="text-light" href="mailto:bd@leadingcloud.xyz">bd@leadingcloud.xyz</a></p>
                            <p style={{position: 'relative'}}><img
                                style={{position: 'absolute', top: '-30px', left: '106px', width: '140px'}} src={phone}
                                alt='里德笔记'/></p>
                        </div>
                        <div className="col-sm-4">
                            <h2 className="mb-4">
                                {/* Services */}
                                服务
                            </h2>
                            <div className="d-sm-flex flex-column">
                                <div style={{cursor: 'pointer'}} onClick={() => {
                                    open('http://support.lidebiji.com/');
                                    BuryingPointFc('VO', 'VO00300100101201', {action: '点击首页服务的帮助中心'})
                                }}>
                                    {/* Help center */}
                                    帮助中心
                                </div>
                                <div style={{cursor: 'pointer'}}
                                     onClick={() => BuryingPointFc('VO', 'VO00300100101301', {action: '点击首页服务的服务条款'})}>
                                    {/* Terms of service */}
                                    服务条款
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <h2 className="mb-4">
                                {/* Careers */}
                                职业机会
                            </h2>
                            <p>
                                <a className="text-light" href="mailto:hr@leadingcloud.xyz">hr@leadingcloud.xyz</a>
                                {/* Please join our official recruit channel after downloading ContentWork App and signing up. */}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="p-5 bg-dark text-light text-center position-relative">
                <div className="container">
                    <p className="lead">
                        版权 &copy; 2023 &nbsp;&nbsp;
                        <a
                            href="https://www.lidebiji.com"
                            style={{color: "inherit", textDecoration: "none"}}
                        >
                            lidebiji.com
                        </a>
                        &nbsp;&nbsp;
                        <a
                            href="https://beian.miit.gov.cn/"
                            style={{color: "inherit", textDecoration: "none"}}
                            target={"_blank"}
                        >
                            粤ICP备2020128353号
                        </a>
                    </p>

                    <a href="#" className="position-absolute bottom-0 end-0 p-5">
                        <i className="bi bi-arrow-up-circle h1"></i>
                    </a>
                </div>
            </footer>
        </>
    )
}

export default FooterFc