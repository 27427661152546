
export const LDPORTAL_API = '/ldportal/api';
export const LDPORTAL = 'ldportal';
export const LDTJUC = 'ldtjuc';
export const LDTJSNS = 'ldtjsns';
export const LDTJTC = 'ldtjtc';
export const LDZSCLOUD = 'ldzscloud';
export const LDSTATIC_RES = 'ldstatic_res';
export const DEV = 'dev';
export const PROD = 'prod';

export const DEV_SERVER: any = {
  [LDPORTAL]: '192.168.1.25:33181', //开发环境
  [LDTJUC]: '192.168.1.25:33181', //开发环境
  [LDTJSNS]: '192.168.1.25:33181', //开发环境
  [LDTJTC]: '192.168.1.25:33181', //开发环境
  [LDZSCLOUD]: '192.168.1.25:33181',
  [LDSTATIC_RES]: 'https://dev.lidetuijian.cn',
};

export const PROD_SERVER: any = {
  [LDTJUC]: 'b.lidebiji.com:33181',
  [LDTJSNS]: 'b.lidebiji.com:33181',
  [LDTJTC]: 'b.lidebiji.com:33181',
  [LDPORTAL]: 'b.lidebiji.com:33181',
};

export const TEST_SERVER = {
  [LDTJUC]: '192.168.1.25:33021',
  [LDTJSNS]: '192.168.1.25:33011',
  [LDTJTC]: '192.168.1.25:33001',
  [LDPORTAL]: '192.168.1.25:33001',
};

export const OSS_UPLOAD_SERVICE_PROD = 'b.lidebiji.com/callback/oss';
export const OSS_UPLOAD_SERVICE_DEV = 'dev.leadingcloud.xyz:33100/callback/oss';
export const APP_DOWNLOAD_URL = 'https://wepro.oss-cn-shenzhen.aliyuncs.com/android/LDREC_Android/1.6.5/ldsns_v1.6.5_release_0321.apk';
