
// import request from '../../commons/util/request';
import { RequestDto } from '../../commons/type/requestDtoType';
import { DEV_SERVER, LDPORTAL_API, PROD_SERVER, LDTJSNS } from '../../commons/constant/constants';
import { post } from '../../commons/util/request';

const isServer = ()=>{
  const env = process.env.ENVIRONMENT;
  let server;
  if (env === 'dev') {
    server = DEV_SERVER[LDTJSNS];
  } else {
    server = PROD_SERVER[LDTJSNS]
  }
  return server;
}


export async function getBestTopicList(params) {
  return post({
    server: isServer(),
    packageName: 'xyz.leadingcloud.grpc.gen.ldsns.topic',
    serviceName: 'TopicService',
    methodName: 'queryTopicList',
    requestMessage: params,
  })
}

export async function queryTopicDetail(params) {
    return post({
    server: isServer(),
    packageName: 'xyz.leadingcloud.grpc.gen.ldsns.topic',
    serviceName: 'TopicService',
    methodName: 'queryTopicDetail',
    requestMessage: params,
  })
}
// 获取创作者分类
export async function queryAllTagCategoryOfCreatorBaseForMerchant(params: {}) {
 return post({
    server: isServer(),
    packageName: 'xyz.leadingcloud.grpc.gen.lduc.user.lduniversal',
    serviceName: 'UcCreatorUniversalService',
    methodName: 'queryAllTagCategoryOfCreatorBase',
    requestMessage: params,
  });
}
// 查询展示在官网的创作者
export async function queryCreatorSquareAll(params: {}) {
  
 return post({
    server: isServer(),
    packageName: 'xyz.leadingcloud.grpc.gen.lduc.user.lduniversal',
    serviceName: 'UcCreatorUniversalService',
    methodName: 'queryCreatorListWebsite',
    requestMessage: params,
  });
}

// export async function queryCompanyById() {
//   return post({
//     server: '192.168.1.117:8280',
//     packageName: 'xyz.leadingcloud.grpc.gen.bm',
//     serviceName: 'CompanyService',
//     methodName: 'queryCompanyById',
//     requestMessage: {
//       companyId: 100001,
//     },
//   });
// }
