
import React, { useEffect, useState } from "react";
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import './index.scss';
import { queryTopicDetail } from "../../../../services/blog";

const ArticleDetail = ({ location }) => {
    const id = location.search.split('=')[1];

    const [topic,setTopic] = useState<any>({});

    useEffect(()=>{
        queryTopicDetailRequest(id)
    },[id])

    const queryTopicDetailRequest = async(id) => {
        const req = {
            userId:0,
            topicId:id
        }
        const res = await queryTopicDetail(req);
        console.log(req,'res:',res)
        if(res?.header?.success){
           setTopic(res?.topic);
        }else{
            window.location.href = '/404'
        }
    }

    return (
        <Layout>
            <Seo title={topic?.title} />
            <div id="detail" className="container p-5">
                <div className="title"><h3>{topic?.title}</h3></div>
                <div className="content" dangerouslySetInnerHTML={{ __html: topic?.content }} />
                {/* <div className="time">{topic?.postTime}</div> */}
            </div>
        </Layout>
    )
}

export default ArticleDetail;